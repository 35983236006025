<template>
	<div class="titlebar titlebar-second">
		<div class="row">
			<div class="col" v-if="workorder.workorder_id">
				Work Order #{{workorder.workorder_id}}: {{workorder.title}}
			</div>
			<div class="col" v-if="!workorder.workorder_id">
				New Work Order
			</div>
			<div class="col text-end">
				<router-link class="nav-link inline-ov" to="/workorders">Close</router-link>
				<a class="nav-link inline-ov ms-5" @click="delete_record" href="#">Delete</a>
				<a class="nav-link inline-ov ms-5" @click="save_changes" href="#">Save Changes</a>
			</div>
		</div>
	</div>
	<div class="container mb-5 mt-3">
		<div class="row">
			<div class="col">
				<h4 v-if="workorder.workorder_id && !edit_workorder_details">{{workorder.title}}</h4>
				<div v-if="!workorder.workorder_id || edit_workorder_details">
					<input type="text" class="form-control" v-model="workorder.title" placeholder="Title, Short Description...">
				</div>
			</div>
			<div class="col-md-3 text-end">
				<select class="form-select text-end" aria-label="Status of Workorder" v-model="workorder.status">
					<option v-for="o,idx in statuses" :key="idx" :value="o">{{o}}</option>
				</select>
			</div>
		</div>
		<hr />
<!-- created_for	
	started_date	
	completed_date -->
		<div class="row">
			<div class="col">
				<label for="created_by_name" class="form-label">Created by</label>
				<input type="text" readonly class="form-control-plaintext" id="created_by_name" v-model="workorder.created_by_name">
			</div>
			<div class="col">
				<label for="created_for" class="form-label">Created For</label>
				<input v-show="!edit_workorder_details" type="text" readonly class="form-control-plaintext" id="created_for" v-model="workorder.created_for">
				<input v-show="edit_workorder_details" type="text" class="form-control" id="created_for" placeholder="Customer Name / Company / Etc." v-model="workorder.created_for">
			</div>
			<div class="col-md-2">
				<label for="assigned_user_id" class="form-label">Assigned to</label>
				<select class="form-select" id="assigned_user_id" aria-label="Default select user" v-model="workorder.assigned_user_id">
					<option value="0"></option>
					<option v-for="u in userlist" :key="u.user_id" :value="u.user_id">{{u.name}}</option>
				</select>
			</div>
			<div class="col">
				<label for="target_start" class="form-label">Target Start</label>
				<input v-show="!edit_workorder_details" type="text" readonly class="form-control-plaintext" id="target_start" v-model="workorder.target_start">
				<input v-show="edit_workorder_details" type="date" class="form-control" id="target_start" placeholder="Customer Name / Company / Etc." v-model="workorder.target_start">
			</div>
			<div class="col">
				<label for="target_complete" class="form-label">Target Completion</label>
				<input v-show="!edit_workorder_details" type="text" readonly class="form-control-plaintext" id="target_complete" v-model="workorder.target_complete">
				<input v-show="edit_workorder_details" type="date" class="form-control" id="target_complete" placeholder="Customer Name / Company / Etc." v-model="workorder.target_complete">
			</div>
			<div class="col">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="billable" v-model="workorder.billable">
					<label class="form-check-label" for="billable">
						Billable
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="archived" v-model="workorder.archived">
					<label class="form-check-label" for="archived">
						Archived
					</label>
				</div>
			</div>
			<div class="col-md-1">
				<button v-show="!edit_workorder_details" type="button" class="btn btn-primary" @click="edit_workorder_details=!edit_workorder_details">Edit</button>
				<button v-show="edit_workorder_details" type="button" class="btn btn-warning" @click="edit_workorder_details=!edit_workorder_details;save_changes">Done</button>
			</div>
		</div>
		<hr />
		<div class="row mt-2">
			
			<div class="col-md-3">
				<label for="started" class="form-label">Date Started</label>
				<div class="input-group">
					<input type="datetime-local" class="form-control" id="started" placeholder="Started Date" v-model="workorder.started" @change="start_date_change">
					<button class="btn btn-outline-secondary" type="button" @click="workorder.started=today(true);start_date_change()"><i class="bi bi-check"></i></button>
					<button class="btn btn-outline-secondary" type="button" @click="workorder.started=null;start_date_change()"><i class="bi bi-x"></i></button>
				</div>
			</div>
			<div class="col-md-3">
				<label for="completed" class="form-label">Date Completed</label>
				<div class="input-group">
					<input type="datetime-local" class="form-control" id="completed" placeholder="Completed Date" v-model="workorder.completed" @change="complete_date_change">
					<button class="btn btn-outline-secondary" type="button" @click="workorder.completed=today(1);complete_date_change()"><i class="bi bi-check"></i></button>
					<button class="btn btn-outline-secondary" type="button" @click="workorder.completed=null;complete_date_change()"><i class="bi bi-x"></i></button>
				</div>
			</div>
			<div class="col-md-3" v-show="workorder.billable==1">
				<label for="started" class="form-label">Date Billed</label>
				<div class="input-group">
					<input type="datetime-local" class="form-control" id="billed" placeholder="Billed Date" v-model="workorder.billed" @change="billed_date_change">
					<button class="btn btn-outline-secondary" type="button" @click="workorder.billed=today(1);billed_date_change()"><i class="bi bi-check"></i></button>
					<button class="btn btn-outline-secondary" type="button" @click="workorder.billed=null;billed_date_change()"><i class="bi bi-x"></i></button>
				</div>
			</div>
			<div class="col-md-3" v-show="workorder.billable==1">
				<label for="invoice_no" class="form-label">Invoice Number</label>
				<input type="text" class="form-control" id="invoice_no" v-model="workorder.invoice_no">
			</div>
		</div>
		<hr />
		<div class="row border rounded">
			<div class="col p-3">
				<!-- <label for="statement_of_work" class="form-label"><strong>Statement of Work</strong></label> -->
				<div v-show="!edit_workorder_details" v-html="markdown_statement_of_work"></div>
				<textarea v-show="edit_workorder_details" rows="8" class="form-control" v-model="workorder.statement_of_work" placeholder="Describe the scope of the work, markdown is supported!"></textarea>
			</div>
		</div>
		<hr />
		<h4>Notes</h4>
		<div class="mt-3 p-2 border rounded">
			<div class="row">
				<div class="col">
					<textarea rows="2" class="form-control" v-model="new_note_body"></textarea>
				</div>
				<div class="col-md-2">
					<button type="button" class="btn btn-secondary" @click="add_note">Add Note</button>
				</div>
			</div>
		</div>
		<div v-if="workorder_loaded && users_loaded">
			<hr />
			<div class="row mt-3" v-for="n in notes" :key="n.workorder_note_id">
				<div class="col-md-3">
					<div>{{get_user_name(n.user_id)}}</div>
					<div><small>{{display_datetime(n.created)}}</small></div>
				</div>
				<div class="col">
					<div v-html="n.markdown"></div>
				</div>
				<hr />
			</div>
		</div>

	</div>
</template>
<script>
	const marked = require('marked')
	const moment = require('moment')

	export default{
		Name:'WorkOrder',
		data(){return{
			workorder: {},
			notes: [],
			edit_workorder_details: false,
			userlist: [],
			new_note_body: null,
			workorder_loaded: false,
			users_loaded: false,
			statuses: ['Open','On Hold','In Progress','Complete','Cancelled','Billed'],
		}},
		computed: {
			markdown_statement_of_work(){
				if(this.workorder.statement_of_work)
					return marked.parse(this.workorder.statement_of_work)
				else
					return ''
			},
		},
		methods:{
			display_date(dt){if(dt) return moment(dt).format("MMM Do YYYY")},
			display_datetime(dt){if(dt) return moment(dt).format("MMM Do YYYY LT")},
			today(time){
				const date = new Date();

				let day = date.getDate()
				if( day < 10 ) day = '0'+String(day)
				let month = date.getMonth() + 1
				if( month < 10 ) month = '0'+String(month)
				let year = date.getFullYear()
				let hour = date.getHours()
				if( hour < 10 ) hour = '0'+String(hour)
				let minute = date.getMinutes()
				if( minute < 10 ) minute = '0'+String(minute)
				let second = date.getSeconds()
				if( second < 10 ) second = '0'+String(second)

				// This arrangement can be altered based on how we want the date's format to appear.
				let currentDate = ''
				// console.log('time: ',time);
				if(!time)
					console.log(`${year}-${month}-${day}`)
				else
					console.log(`${year}-${month}-${day}T${hour}:${minute}:${second}`)
				if(!time)
					return `${year}-${month}-${day}`
				else
					return `${year}-${month}-${day} ${hour}:${minute}:${second}`
			},
			start_date_change(){
				if(this.workorder.started)
					this.workorder.status = 'In Progress'
			},
			complete_date_change(){
				if(this.workorder.completed)
					this.workorder.status = 'Complete'
			},
			billed_date_change(){
				if(this.workorder.billed)
					this.workorder.status = 'Billed'
			},
			save_changes(){
				let v = this
				v.axios.post('/api/save_workorder',v.workorder).then(response=>{
					v.router.push('/workorders')
				})
			},
			get_user_name(id){
				for(let u of this.userlist)
				{
					if( String(u.user_id) == String(id) )
						return u.name
				}
				return 'Nope'
			},
			fetch_users(){
				let v = this
				v.axios.get('/api/userlist').then(response=>{
					v.userlist = response.data
					for(let u of v.userlist)
						u.user_id = parseInt(u.user_id)
					v.users_loaded = true
				})
			},
			load(workorder_id){
				let v = this
				v.axios.get('/api/workorder/'+String(workorder_id)).then(response=>{
					v.workorder = response.data
					v.load_notes(workorder_id)
					v.workorder_loaded = true
				})
			},
			load_notes(workorder_id){
				let v = this
				v.axios.get('/api/workordernotes/'+String(workorder_id)).then(response=>{
					v.notes = response.data
					for(let n of v.notes)
						n.markdown = marked.parse(n.note_body)
				})
			},
			add_note(){
				let v = this
				let data = {
					workorder_id: v.workorder.workorder_id,
					note_body: v.new_note_body,
				}
				v.axios.post('/api/addworkordernote',data).then(response=>{
					v.load_notes(v.workorder.workorder_id)
					v.new_note_body = '';
				})
			},
			delete_record(){
				let v = this
				if( window.prompt('Type DELETE to confirm removal') == 'DELETE' )
					v.axios.post('/api/del_workorder',{workorder_id: v.workorder.workorder_id}).then(response=>{
						v.router.push('/workorders')
					})
			},
			status_class(){
				switch(this.workorder.status){
					case '':
					case 'Open':
						return 'bg-success'
					case 'On Hold':
						return 'bg-secondary'
					case 'Assigned':
					case 'In Progress':
						return 'bg-info'
					case 'Complete':
					case 'Cancelled':
						return 'bg-secondary'
					default:
						return 'bg-dark'
				}				
			},
		},
		mounted(){
			this.fetch_users()
			let id = this.$route.params.id
			if( id )
			{
				this.$nextTick(this.load(id))
				this.$nextTick(this.load_notes(id))
			}
			else{
				this.workorder_loaded = true
				this.edit_workorder_details = true
				this.workorder.status = "Open"
				this.workorder.workorder_id = null
			}
		}
	}
</script> 
