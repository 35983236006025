import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./app.scss" // Customized Bootstrap - imports the bootstrap css when parsed
import bootstrap from 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css"
import Cookies from 'js-cookie'
import {ajax,kill_token} from '@/ajax.js' // Default Instance of Axios

const app = createApp(App)

app.use(router)
app.config.globalProperties.axios = ajax
app.config.globalProperties.kill_token = kill_token
app.config.globalProperties.cookies = Cookies
app.config.globalProperties.router = router
app.mount('#app')
