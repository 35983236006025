<template>
	<div class="titlebar titlebar-second">
		<div class="row">
			<div class="col">
				Category
				<span v-if="category.category_id">: {{category.name}}</span>
			</div>
			<div class="col text-end">
				<router-link to="/mcategories" class="nav-link inline-ov ms-5">Close</router-link>
				<a href="#" @click="remove" class="nav-link inline-ov ms-5">Delete</a>
				<a href="#" @click="save" class="nav-link inline-ov ms-5">Save Changes</a>
			</div>
		</div>
	</div>
	<div class="container mt-3">
		<div class="row">
			<div class="col">
				<h3>Subject Category</h3>
				<div class="mb-3">
					<label for="name" class="form-label">Name</label>
					<input type="email" class="form-control" id="name" placeholder="eg. Red Servers Group" v-model="category.name">
				</div>
				<div class="mb-3">
					<label for="description" class="form-label">Description</label>
					<textarea class="form-control" id="description" rows="3" v-model="category.description"></textarea>
				</div>
			</div>
			<div class="col">
				<h4>Optional Fields</h4>
				<div v-for="f,idx in optional_fields" :key="idx">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" v-model="category.optional_fields" :value="f.value" :id="'optional_field_'+String(idx)">
						<label class="form-check-label" :for="'optional_field_'+String(idx)">
							{{f.label}}
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		Name: 'MaintenanceCategory',
		data(){return{
			category: {
				optional_fields: [],
			},
			category_loaded: false,
			modules: [],
			optional_fields: [
				{value:'assigned_user_id',label:'Assigned User'},
				{value:'domain',label:'Domain'},
				{value:'customer_name',label:'Customer Name'},
				{value:'customer_whmcs',label:'WHMCS Instance'},
				{value:'budget',label:'Budget'},
			],
		}},
		methods: {
			load(id){
				let v = this
				v.axios.get('/api/category/'+String(id)).then(response=>{
					v.category = response.data
					if( v.category.optional_fields )
						v.category.optional_fields = JSON.parse(v.category.optional_fields)
					else
						v.category.optional_fields = []
					v.category_loaded = true
				})
			},
			save(){
				let v = this
				let payload = Object.assign({}, v.category);
				payload.modules = Object.assign({},v.modules);
				v.axios.post('/api/category',payload).then(response=>{
					v.router.push('/mcategories')
				})
			},
			remove(){
				let v = this
				if( window.prompt('Type DELETE to confirm removal') == 'DELETE' )
					v.axios.delete('/api/category/'+String(v.category.category_id)).then(response=>{
						v.router.push('/mcategories')
					})
			},
		},
		mounted(){
			let id = this.$route.params.id
			this.load(id)
		},
	}
</script> 
