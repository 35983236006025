<template>
	<div v-if="access_denied">
		<h1 class="mt-5 text-center">Access Denied</h1>
	</div>
	<span v-if="!access_denied">
		<div class="titlebar titlebar-second">
			<div class="row">
				<div class="col">
					Maintenance Subject
				</div>
				<div class="col text-end">
					<router-link class="nav-link inline-ov" :to="'/categorydashboard/'+String(subject.category_id)">Close</router-link>
					<a class="nav-link inline-ov ms-5" @click="remove" href="#">Delete</a>
					<a class="nav-link inline-ov ms-5" :class="{'text-bg-warning':subject.subject_id<=0}" @click="save_changes(false)" href="#">Save Changes</a>
				</div>
			</div>
		</div>
		<div class="container-fluid mt-3">
			<div v-if="subject_loaded">
				<div class="row">
					<div class="col-md-4">
						<div class="mb-3">
							<label for="name" class="form-label">Subject Name/Designation</label>
							<input type="text" class="form-control" id="name" placeholder="eg. server123.easyonnet.cloud" v-model="subject.name">
						</div>
						<div v-if="optional_fields.indexOf('domain') != -1">
							<label for="domain" class="form-label">Domain</label>
							<input type="text" class="form-control" id="domain" placeholder="eg. acme.com" v-model="subject.domain">
						</div>
						<div class="mb-3">
							<label for="tagline" class="form-label">Tagline</label>
							<textarea class="form-control" id="tagline" placeholder="eg. a shared hosting server" v-model="subject.tagline"></textarea>
							<p class="text-muted"><small>A short description for this item, shown on the dashboard to help identify it.</small></p>
						</div>
						<div class="mb-3">
							<div class="row">
								<div class="col">
									<label for="cycle" class="form-label">Service Cycle</label>
									<select class="form-select" v-model="subject.cycle">
										<option v-for="c,idx in cycles" :key="idx" :value="c">
											{{capitalizeFirstLetter(c)}}
										</option>
									</select>
								</div>
								<div class="col" v-if="optional_fields.indexOf('budget') != -1">
									<div >
										<label for="budget" class="form-label">Budget</label>
										<div class="input-group">
											<div class="input-group-text"><i class="bi bi-currency-dollar"></i></div>
											<input type="number" class="form-control" id="budget" placeholder="eg. 500" v-model="subject.budget" step="25" min="0">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="optional_fields.indexOf('customer_name') != -1">
							<label for="customer_name" class="form-label">Customer Name</label>
							<input type="text" class="form-control" id="customer_name" placeholder="eg. ACME Inc." v-model="subject.customer_name">
						</div>
						<div v-if="optional_fields.indexOf('customer_whmcs') != -1">
							<label for="customer_whmcs" class="form-label">Customer WHMCS Instance</label>
							<select class="form-select" v-model="subject.customer_whmcs">
								<option></option>
								<option value="eon">Easy On Net WHMCS</option>
								<option value="nerivon">Nerivon WHMCS</option>
								<option value="metisentry">Metisentry WHMCS</option>
							</select>
						</div>
						<div v-if="optional_fields.indexOf('assigned_user_id') != -1">
							<label for="assigned_user_id">Assigned User</label>
							<select class="form-select" v-model="subject.assigned_user_id">
								<option></option>
								<option v-for="u,idx in users" :key="idx" :value="u.user_id">{{u.name}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-8">
						<div class="row" v-show="subject.subject_id > 0">
							<div class="col-sm-4">
								<label for="status" class="form-label">Status</label>
								<select class="form-select" v-model="subject.status">
									<option v-for="s,idx in statuses" :key="idx" :value="s.value">{{s.caption}}</option>
								</select>
							</div>
							<div class="col-sm-4">
								<label for="last_service" class="form-label">Last Service</label>
								<input type="datetime-local" class="form-control" id="last_service" v-model="subject.last_service">
							</div>
							<div class="col-sm-4">
								<label for="next_service" class="form-label">Next Service Due</label>
								<input type="date" class="form-control" id="next_service" v-model="subject.next_service">
							</div>
						</div>

						<ul class="nav nav-tabs mt-5" v-show="subject.subject_id > 0">
							<li class="nav-item">
								<a class="nav-link" :class="{active:tab=='instructions'}" href="javascript:void(0)" @click="tab='instructions'">Special Instructions &amp; Notes</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" :class="{active:tab=='logs'}" href="javascript:void(0)" @click="tab='logs'">Service Logs</a>
							</li>
						</ul>

						<div class="mt-3" v-show="tab=='instructions' && subject.subject_id > 0">
							<div v-if="!edit_notes">
								<div class="row">
									<div class="col-md-8">
										<label class="form-label">Special Instructions / Notes</label>
									</div>
									<div class="col-md-4 text-end">
										<button type="button" class="btn btn-sm btn-primary" @click="edit_notes=true">Edit Notes</button>
									</div>
								</div>
								<hr />
								<div v-html="markdown_subject_notes"></div>
							</div>
							<div v-if="edit_notes">
								<div class="row">
									<div class="col-md-8">
										<label class="form-label">Special Instructions / Notes</label>
									</div>
									<div class="col-md-4 text-end">
										<button type="button" class="btn btn-sm btn-secondary" @click="edit_notes=false">Protect Notes</button>
									</div>
								</div>
								<hr />
								<textarea class="form-control" rows="15" id="notes" v-model="subject.notes"></textarea>
							</div>
						</div>

						<div v-show="tab=='logs' && subject.subject_id > 0">
							<div class="row mt-3">
								<div class="col-md-6">
									<label class="form-label">Service Logs</label>
								</div>
								<div class="col-md-6 text-end">
									<button type="button" class="btn btn-sm btn-primary" @click="init_new_log();add_log_visible=true" v-show="!add_log_visible">Add Service Log</button>
								</div>
							</div>
							<div id="new-service-log-form" v-show="add_log_visible">
								<hr />
								<div class="row">
									<div class="col-md-6">
										<div class="mb-3">
											<label class="form-label" for="log_completed">Date Completed</label>
											<input type="datetime-local" class="form-control" id="log_completed" v-model="log.completed">
										</div>
										<div class="md-3">
											<label class="form-label" for="log_next_service">Next Service Date</label>
											<input type="date" class="form-control" id="log_next_service" v-model="log.next_service">
										</div>
										<div class="md-3 mt-1">
											<button type="button" class="btn btn-sm btn-light" @click="init_new_log">Recalculate Dates/Times</button>
										</div>
									</div>
									<div class="col-md-6">
										<div>
											<label class="form-label" for="result">Result</label>
											<select class="form-select" v-model="log.result">
												<option value="complete">Complete</option>
												<option value="failed">Failure</option>
												<option value="incomplete">Incomplete</option>
											</select>
										</div>
										<div>
											<label class="form-label" for="log_notes">Service Notes</label>
											<textarea class="form-control" id="log_notes" rows="3" v-model="log.notes"></textarea>
										</div>
										<div class="text-end">
											<button type="button" class="btn btn-sm btn-primary mt-2 me-1" @click="add_log_visible=false">Cancel</button>
											<button type="button" class="btn btn-sm btn-primary mt-2" @click="add_log();add_log_visible=false">Save Log</button>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<table class="table table-striped">
								<thead>
									<tr>
										<th>Log Date</th>
										<th>User</th>
										<th>Result</th>
									</tr>
								</thead>
								<tbody v-for="l in logs" :key="l.log_id">
									<tr>
										<td>{{fmt_datetime(l.completed)}}</td>
										<td>{{l.full_name}}</td>
										<td>
											<div class="badge bg-success" v-show="l.result=='complete'">Completed</div>
											<div class="badge bg-danger" v-show="l.result=='failed'">Failure</div>
											<div class="badge bg-warning" v-show="l.result=='incomplete'">Incomplete</div>
										</td>
									</tr>
									<tr v-show="l.notes">
										<td colspan="3">
											<i class="bi bi-journal-text ms-2 me-3"></i> {{l.notes}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</span>
</template>
<script>
	const marked = require('marked')
	const moment = require('moment')
	import {today,display_date,display_datetime,html_date,html_datetime} from '@/MegaDate.js'

	export default{
		Name: 'UserEdit',
		data(){return{
			tab: 'instructions',
			subject_loaded: false,
			subject: {},
			access_denied: false,
			edit_notes: false,
			statuses: [
				{value:'ok',caption:'OK'},
				{value:'overdue',caption:'Overdue'},
				{value:'hold',caption:'On Hold'},
				{value:'cancelled',caption:'Cancelled'},
			],
			cycles: ['daily','weekly','bi-weekly','monthly','bi-monthly','quarterly','annually','bi-annually'],
			log: {
				subject_id: null,
				completed: today(true),
				next_service: null,
				notes: null,
				result: 'complete',
			},
			logs: [],
			logs_loaded: false,
			add_log_visible: false,
			category: {},
			optional_fields: [],
			users: [],
			//  {value:'assigned_user_id',label:'Assigned User'},
			// 	{value:'domain',label:'Domain'},
			// 	{value:'customer_name',label:'Customer Name'},
			// 	{value:'customer_whmcs',label:'WHMCS Instance'},
			// 	{value:'budget',label:'Budget'},
		}},
		computed: {
			markdown_subject_notes(){
				if(this.subject.notes)
					return marked.parse(this.subject.notes)
				else
					return ''
			},
		},
		methods:{
			fmt_datetime(dt){
				return display_datetime(dt)
			},
			fmt_date(dt){
				return display_date(dt)
			},
			show_notes(notes){
				window.alert(notes)
			},
			init_new_log(){
				let v = this
				let dt = moment()

				v.log.subject_id = v.subject.subject_id
				v.log.completed = today(true)

				switch(v.subject.cycle)
				{
					case 'daily':
						dt = dt.add(1,'days')
						break;
					case 'weekly':
						dt = dt.add(7,'days')
						break;
					case 'bi-weekly':
						dt = dt.add(14,'days')
						break;
					case 'monthly':
						dt = dt.add(1,'months')
						break;
					case 'bi-monthly':
						dt = dt.add(2,'months')
						break;
					case 'quarterly':
						dt = dt.add(3,'months')
						break;
					case 'annually':
						dt = dt.add(1,'years')
						break;
					case 'bi-annually':
						dt = dt.add(2,'years')
						break;
				}
				v.log.next_service = html_date(dt)
			},
			capitalizeFirstLetter(string) {
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			load_subject(sid){
				let v = this
				v.subject_loaded = false
				v.axios.get('/api/subject/'+String(sid)).then(response=>{
					v.subject = Object.assign({},response.data)
					delete v.subject.optional_fields
					if( response.data.optional_fields )
					{
						v.optional_fields = response.data.optional_fields
					}
					else
						v.optional_fields = []
					v.subject_loaded = true
				}).catch(error=>{
					if( error.response.data == 'Access Denied' )
						v.access_denied = true
				})
			},
			load_users(){
				const v = this
				v.axios.get('/api/userlist').then(response=>{
					v.users = response.data
				})
			},
			load_logs(sid){
				let v = this
				v.logs_loaded = false
				v.axios.get('/api/logs',{params:{subject_id:sid}}).then(response=>{
					v.logs = response.data
					v.logs_loaded = true
				})
			},
			save_changes(stay_on_page=false){
				let v = this
				v.axios.post('/api/subject',v.subject).then(response=>{
					if( stay_on_page )
						v.load_subject(response.data.subject_id)
					else
						if( v.subject.subject_id )
							v.router.push('/categorydashboard/'+String(v.subject.category_id));
						else
							v.load_subject(response.data.subject_id)
				})
			},
			remove(){
				let v = this
				let category_id = v.subject.category_id
				if( window.prompt('Type DELETE to confirm removal') == 'DELETE' )
					v.axios.post('/api/del_subject',{subject_id:v.subject.subject_id}).then(response=>{
						v.router.push('/categorydashboard/'+String(category_id));
					})
			},
			add_log(){
				let v = this
				v.subject.last_service = v.log.completed
				v.subject.next_service = v.log.next_service
				v.subject.status = 'ok'
				v.save_changes(true)
				v.axios.post('/api/log',v.log).then(response=>{
					v.load_logs(v.subject.subject_id)
				})
			},
		},
		mounted(){
			let id = this.$route.params.id
			this.init_new_log()
			this.load_users();
			if( id )
			{
				this.load_subject(id)
				this.load_logs(id)
			}
			else
			{
				this.subject.category_id = this.cookies.get('maintenance_category_id')
				this.subject.subject_id = null
				this.subject_loaded = true
				this.edit_notes = true
				this.logs_loaded = false
			}
		},
	}
</script> 
