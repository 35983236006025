<template>
	<MainNav />
	<router-view></router-view>
</template>

<script>
import MainNav from "@/components/MainNav.vue"
export default {
	name: 'App',
	components: {MainNav},
	methods: {},
	mounted(){
		let v = this
		if(!v.cookies.get('jwt'))
			v.$router.push('/login')
	},
}
</script>

<style>
	.router-container{
		min-height: 60%;
		padding-top: 0px;
		padding-bottom: 200px;
	}
</style>
