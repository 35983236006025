<template>
	<div class="container">
		<div class="row mt-5">
			<div class="col-md-3 border rounded bg-light">
				<div class="mt-3">Work Orders</div>
				<div class="p-3" v-if="workorder_stat_loaded">
					<div class="row">
						<div class="col-md-8">
							Active
						</div>
						<div class="col">
							{{workorder_stat.active}}
						</div>
					</div>
					<div class="row">
						<div class="col-md-8">
							Ready for Billing
						</div>
						<div class="col">
							{{workorder_stat.billable}}
						</div>
					</div>
					<div class="row">
						<div class="col-md-8">
							Archived
						</div>
						<div class="col">
							{{workorder_stat.archived}}
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-9">
				<AlertTable />
			</div>
		</div>
	</div>
</template> 
<script>
	import AlertTable from '@/components/AlertTable.vue'
	export default{
		name: "DashBoard",
		components: {AlertTable},
		data(){return{
			names: [],
			loggedin: false,
			four: false,
			workorder_stat: {},
			workorder_stat_loaded: false,
		}},
		methods: {
			logout(){
				let v = this
				v.kill_token()
				v.router.push('/login')
			},
			check(){
				let v = this
				v.loggedin = v.check_token()
			},
			load_workorder_stats(){
				let v = this
				v.axios.get('/api/wo_stat').then(response=>{
					v.workorder_stat = response.data
					v.workorder_stat_loaded = true
				})
			},
		},
		mounted(){
			this.load_workorder_stats()
		},

	}
</script>
