<template>

	<div class="border rounded p-3 bg-light">

		<div class="row">
			<div class="col-md-6">
				<h3>Alerts</h3>
			</div>
			<div class="col-md-6 text-end">
				<button type="button" class="btn btn-sm btn-outline-secondary" @click="dismiss_all">Clear All Alerts</button>
			</div>
		</div>
		<div v-for="a,idx in alerts" :key="idx" class="alert alert-warning alert-dismissible">
			<div v-if="a.type=='updated'">
				<a href="javascript:void(0)" @click="alert_open_workorder(a)" >Workorder {{a.workorder_id}}</a> was <strong>Updated</strong>
			</div>
			<div v-if="a.type=='status-changed'">
				The Status of <a href="javascript:void(0)" @click="alert_open_workorder(a)">Workorder {{a.workorder_id}}</a> was <strong>Changed</strong>
			</div>
			<div v-if="a.type=='note-added'">
				A Note was added on <a href="javascript:void(0)" @click="alert_open_workorder(a)" >Workorder {{a.workorder_id}}</a>
			</div>
			<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="dismiss(a)"></button>
		</div>

	</div>

</template> 
<script>
	export default{
		name: 'AlertTable',
		props: {},
		data(){return{
			alerts: [],
		}},
		methods: {
			load_alerts(){
				const v = this
				v.axios.get('/api/alerts').then(response=>{
					v.alerts = response.data
				}).catch(e=>{
					alert('Something went wrong')
				})
			},
			dismiss_all(){
				const v = this
				if( window.confirm('Are you sure you want to clear all alerts?') ) 
				{
					for(let alert of v.alerts)
					{
						v.axios.delete('/api/alert/'+alert.workorder_alert_id);
					}
					v.alerts = [];
					setTimeout(v.load_alerts,5000);
				}
			},
			dismiss(alert){
				// called when we dismiss a particular alert
				const v = this
				v.axios.delete('/api/alert/'+alert.workorder_alert_id).then(response=>{
					v.load_alerts()
				}).catch(e=>{
					alert('something went wrong')
				})
			},
			alert_open_workorder(alert){
				// Opens a workorder in a new tab
				const v = this
				v.axios.delete('/api/alert/'+alert.workorder_alert_id).then(response=>{
					v.$router.push('/workorder/'+alert.workorder_id)
				}).catch(e=>{
					alert('something went wrong')
				})
			},
		},
		mounted(){
			const v = this
			v.load_alerts()
		}
	}
</script>