<template>
	<div class="titlebar titlebar-second">
		<div class="row">
			<div class="col">
				Maintenance Dashboard
			</div>
			<div class="col text-end">
				<router-link to="/mcategories" class="nav-link">Maintenance Categories</router-link>
			</div>
		</div>
	</div>
	<div class="container mt-3">
		<div class="row">
			<div class="col-md-3 mt-3" v-for="c in categories" :key="c.category_id">
				<div class="border rounded bg-light ps-1 pt-3 pb-3 hover-effect" @click="open_category(c.category_id)"> 
					<div class="row">
						<div class="col-md-9 text-end">
							<strong>{{c.name}}</strong>
						</div>
						<div class="col-md-3">
							<div class="badge bg-primary">
								{{c.total}}
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-9 text-end">
							Due within 5 Days
						</div>
						<div class="col-md-3">
							<div class="badge bg-warning">
								{{c.due_soon}}
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-9 text-end">
							Overdue
						</div>
						<div class="col-md-3">
							<div class="badge bg-danger">
								{{c.overdue}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-3 mt-3">
				<div class="border rounded bg-white ps-1 pt-3 pb-3 hover-effect" style="border-style: dashed !important;" @click="add_category"> 
					<div>&nbsp;</div>
					<div class="text-center text-muted">Add New Category</div>
					<div>&nbsp;</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		Name: "MaintenanceDashboard",
		data(){return{
			categories: [],
		}},
		methods: {
			add_category(){
				this.$router.push('/category')
			},
			open_category(id){
				this.$router.push('/categorydashboard/'+String(id))
			},
			load_categories(){
				let v = this
				v.axios.get('/api/category_summary').then(response=>{
					this.categories = response.data
				})
			},
		},
		mounted(){
			this.load_categories();
		},
	}
</script> 
