import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import LoginView from './components/views/LoginView.vue'
import DashBoard from './components/views/DashBoard.vue'
import WorkOrders from './components/views/WorkOrders.vue'
import WorkOrder from './components/views/WorkOrder.vue'
import UsersView from './components/views/UsersView.vue'
import UserEdit from './components/views/UserEdit.vue'
import MaintenanceDashboard from './components/views/MaintenanceDashboard.vue'
import MaintenanceCategories from './components/views/MaintenanceCategories.vue'
import MaintenanceCategory from './components/views/MaintenanceCategory.vue'
import MaintenanceCategoryDashboard from './components/views/MaintenanceCategoryDashboard.vue'
import MaintenanceSubject from './components/views/MaintenanceSubject.vue'

const routes = [
  { path: '/', component: DashBoard},
  { path: '/login', component: LoginView },
  { path: '/workorders', component: WorkOrders },
  { path: '/workorder/:id',component: WorkOrder },
  { path: '/workorder',component: WorkOrder},
  { path: '/users',component: UsersView },
  { path: '/user/:id',component: UserEdit },
  { path: '/user',component:UserEdit},
  { path: '/mcategories',component: MaintenanceCategories},
  { path: '/maintenance',component: MaintenanceDashboard},
  { path: '/category',component: MaintenanceCategory}, // add new category
  { path: '/category/:id',component: MaintenanceCategory}, //edit existing category
  { path: '/categorydashboard/:id',component: MaintenanceCategoryDashboard},
  { path: '/subject/:id',component: MaintenanceSubject}, // edit existing subject
  { path: '/subject',component: MaintenanceSubject}, // add new subject
]

const router = createRouter({
  // history: createWebHashHistory('/'),
	history: createWebHistory('/'),
	routes,
})

export default router
 
