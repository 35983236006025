<template>
	<div v-if="shownav">
		<div class="titlebar">
			<div class="row">
				<div class="col-md-10">
					Mtracker - Maintenance and Work Order Tracking for Easy On Net - Version {{version}}
				</div>
				<div class="col text-end">
					<a class="nav-link" @click="logout" href="#">Log Out</a>
				</div>
			</div>
		</div>
		<ul class="nav titlebar titlebar-menus">
			<li class="nav-item">
				<router-link class="nav-link" to="/">Home</router-link>
			</li>
			<li class="nav-item">
				<router-link class="nav-link" to="/maintenance">Maintenance</router-link>
			</li>
			<!-- <li class="nav-item">
				<div class="dropdown">
					<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Maintenance</a>
					<ul class="dropdown-menu">
						<li><a class="dropdown-item" href="#">Managed Customer Servers</a></li>
						<li><a class="dropdown-item" href="#">Managed Easy On Net Servers</a></li>
						<li><a class="dropdown-item" href="#">Managed Wordpress Sites</a></li>
						<li><a class="dropdown-item" href="#">Managed Drupal Sites</a></li>
					</ul>
				</div>
			</li> -->
			<li class="nav-item">
				<router-link class="nav-link" to="/workorders">WorkOrders</router-link>
			</li>
			<li class="nav-item">
				<router-link class="nav-link" to="/users">Users</router-link>
			</li>
		</ul>
	</div>
</template>
<script>
	import {app_version,app_version_date} from '@/ver.js'
	export default{
		Name: 'MainNav',
		data(){ return {
			shownav: false,
			version: app_version,
		}},
		methods: {
			logout(){
				let v = this
				v.kill_token()
				v.router.push('/login')
			},
		},
		watch: {
			$route(to, from){
				let v = this
				if(v.cookies.get('jwt'))
					v.shownav = true
				else
					v.shownav = false
			}
		},
	}
</script> 
