<template>
	<div class="containment">
		<div class="titlebar titlebar-second">
			<div class="row">
				<div class="col">
					User Accounts
				</div>
				<div class="col text-end">
					<router-link to="/user" class="nav-link">Add User</router-link>
				</div>
			</div>
		</div>
		<div class="container mt-3" v-if="users_loaded">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th>User</th>
						<th>Email</th>
						<th>Mattermost</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="u in users" :key="u.user_id" @click="open_user(u.user_id)">
						<td>{{u.full_name}}</td>
						<td>{{u.email}}</td>
						<td>@{{u.mattermost_id}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
	export default{
		Name: 'UsersView',
		data(){return{
			users_loaded: false,
			users: [],
		}},
		methods:{
			open_user(uid){
				this.router.push('/user/'+String(uid))
			},
			load_users(){
				let v = this
				v.axios.get('/api/users').then(response=>{
					v.users = response.data
					v.users_loaded = true
				})
			},
		},
		mounted(){
			this.load_users()
		}
	}
</script> 
