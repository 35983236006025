<template>
	<div class="titlebar titlebar-second">
		<div class="row">
			<div class="col">
				Work Orders
			</div>
			<div class="col text-end">
				<router-link to="/workorder" class="nav-link">Add Work Order</router-link>
			</div>
		</div>
	</div>
	<div class="container mt-3">
		<div class="border rounded mb-3 p-3">
			<div class="row">
				<div class="col">
					<label class="form-label">Search by Keyword (titles)</label>
					<input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Type to Search by Keyword" @change="apply_filters" v-model="filters.keyword">
				</div>
				<div class="col">
					<label class="form-label">Created by</label>
					<select class="form-select" aria-label="Assigned to" @change="apply_filters" v-model="filters.creator">
						<option value=""></option>
						<option v-for="u,idx in users" :key="idx" :value="u.user_id">{{u.name}}</option>
					</select>
				</div>
				<div class="col">
					<label class="form-label">Assigned to</label>
					<select class="form-select" aria-label="Assigned to" @change="apply_filters" v-model="filters.assigned">
						<option value=""></option>
						<option v-for="u,idx in users" :key="idx" :value="u.user_id">{{u.name}}</option>
					</select>
				</div>
				<div class="col">
					<label class="form-label">Status</label>
					<select class="form-select" aria-label="Status" @change="apply_filters" v-model="filters.status">
						<option value=""></option>
						<option v-for="o,idx in statuses" :key="idx" :value="o">{{o}}</option>
					</select>
				</div>
				<div class="col-md-1">
					<button type="button" class="btn btn-secondary" @click="reset_filters">Reset</button>
					<div class="form-check mt-3">
						<input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="archive" v-model="filters.archive" @change="apply_filters">
						<label class="form-check-label" for="archive">
							Archives
						</label>
					</div>
				</div>
			</div>
		</div>
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th>ID</th><th>Created</th><th>Title</th><th>Assigned to</th><th>Timeline</th><th>Status</th><th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="wo in workorders" :key="wo.workorder_id">
					<td @click="row_click(wo.workorder_id)">{{wo.workorder_id}}</td>
					<td @click="row_click(wo.workorder_id)">{{display_date(wo.created)}}</td>
					<td @click="row_click(wo.workorder_id)">{{wo.title}}</td>
					<td @click="row_click(wo.workorder_id)">{{wo.assigned_to}}</td>
					<td @click="row_click(wo.workorder_id)">{{render_dates(wo)}}</td>
					<td @click="row_click(wo.workorder_id)">
						<div class="badge" :class="status_class(wo)">{{wo.status}}</div>
					</td>
					<td>
						<a href="javascript:void" @click="clip_url(wo.url)" title="Copy to Clipboard">
							<i class="bi bi-box-arrow-up-right"></i>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	// import {moment} from 'moment'
	const moment = require('moment')

	export default{
		Name: 'WorkOrders',
		data(){return{
			workorders: [],
			filters: {
				status: 'All Active',
				assigned: '',
				keyword: '',
				creator: '',
				archive: 0,
			},
			statuses: ['All Active','Ready for Billing','Open','On Hold','In Progress','Complete','Cancelled','Billed'],
			users: [],
		}},
		methods:{
			display_date(dt){
				return moment(dt).format("MMM Do YYYY")
			},
			row_click(id){
				this.$router.push('/workorder/'+String(id))
			},
			render_dates(wo){
				let data = []
				let target_start = new Date(String(wo.target_start)+'T00:00:01')
				let target_complete = new Date(String(wo.target_complete)+'T11:59:59')
				if( wo.archived == 1 ) 
					return 'Archived'
				if( wo.billed )
					return 'Billed '+moment(wo.billed).fromNow()
				if( !wo.started && wo.target_start && wo.target_complete )
				{
					let dt = Date.parse(String(wo.target_complete)+'T00:00:01')
					dt = new Date(dt)
					let now = new Date()
					if( dt.getDate() == now.getDate() && dt.getYear() == now.getYear() && dt.getMonth() == now.getMonth() )
						return 'Due Today!'
					else
						return 'Due '+moment(wo.target_complete).fromNow()
				}
				if( !wo.started && wo.target_start )
					return 'Starting '+moment(wo.target_start).fromNow()
				if( wo.started )
					return 'Started '+moment(wo.started).fromNow()
				if( wo.created )
					return 'Created '+moment(wo.created).fromNow()
				return ''
			},
			status_class(wo){
				switch(wo.status){
					case '':
					case 'Open':
						return 'bg-success'
					case 'On Hold':
						return 'bg-danger'
					case 'Assigned':
					case 'In Progress':
						return 'bg-warning'
					case 'Complete':
						return 'bg-secondary'
					default:
						return 'bg-dark'
				}				
			},
			load(){
				let v = this
				v.axios.get('/api/workorders',{params:v.filters}).then(response=>{
					v.workorders = response.data
					for(let w of v.workorders)
						w.url = window.location.protocol+'//'+window.location.host+'/#/workorder/'+String(w.workorder_id);
				})
			},
			load_users(){
				let v = this
				v.axios.get('/api/userlist').then(response=>{
					v.users = response.data
				})
			},
			apply_filters(){
				let v = this

				// Set Filter Cookies for all filters on this page
				for(let f in v.filters)
				{
					//v.cookies.remove('workorders.filter.'+String(f))
					if( v.filters[f] )
						v.cookies.set('workorders.filter.'+String(f),v.filters[f],{ expires: 7 })
				}

				v.load()
			},
			load_filters(){
				let v = this
				for(let f in v.filters)
				{
					let setting = v.cookies.get('workorders.filter.'+String(f))
					if( setting )
						v.filters[f] = setting
				}
			},
			reset_filters(){
				let v = this
				for(let f in v.filters)
				{
					if( f == 'status' )
					{
						v.filters.status = 'All Active'
						v.cookies.set('workorders.filter.status','All Active',{expires:7})
					}
					else
					{
						v.filters[f] = ''
						v.cookies.remove('workorders.filter.'+String(f))
					}
				}
				v.apply_filters()
			},
			clip_url(url){
				navigator.clipboard.writeText(url);
				alert("Copied the text: " + url);
			},
		},
		mounted(){
			this.load_filters()
			this.load_users()
			this.load()
		}
	}
</script> 
