<template>
	<div class="titlebar titlebar-second">
		<div class="row">
			<div class="col">
				Categories
			</div>
			<div class="col text-end">
				<router-link to="/maintenance" class="nav-link inline-ov ms-5">Close</router-link>
				<router-link to="/category" class="nav-link inline-ov ms-5">Add Maintenance Category</router-link>
			</div>
		</div>
	</div>
	<div class="container mt-3">
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th>Category Name</th>
					<th>Description</th>
					<th>Created</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="c in categories" :key="c.m_category_id" @click="open_category(c.category_id)">
					<td>{{c.name}}</td>
					<td>{{c.description}}</td>
					<td>{{c.created}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default{
		Name: "MaintenanceCategories",
		data(){return{
			categories: [],
		}},
		methods: {
			open_category(id){
				this.$router.push('/category/'+String(id));
			},
			load_categories(){
				let v = this
				v.axios.get('/api/categories').then(response=>{
					this.categories = response.data
				})
			}
		},
		mounted(){
			this.load_categories()
		}
	}
</script> 
