<template>
	<div class="border rounded p-5 m-5 login-form">
		<h1>Login to MTracker</h1>
		<div>
			<div class="mb-3">
				<label for="exampleInputEmail1" class="form-label">Email address</label>
				<input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email" @keyup="submitme">
				<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
			</div>
			<div class="mb-3">
				<label for="exampleInputPassword1" class="form-label">Password</label>
				<input type="password" class="form-control" id="exampleInputPassword1" v-model="password" @keyup="submitme">
			</div>
			<div class="mt-2 mb-2" v-if="msg">
				<div class="badge bg-danger">{{msg}}</div>
			</div>
			<div class="md-3 text-end">
				<button type="button" class="btn btn-dark" @click="login">Login</button>
			</div>
		</div>
		<div><small>Spring 2024</small></div>
	</div>
</template>
<script>
export default {
	Name: 'LoginForm',
	data(){return{
		email: '', 
		password: '',
		msg: '',
	}},
	methods: {
		submitme(e){
			if( e.key === 'Enter' || e.keyCode === 13 )
				this.login()
		},
		login(){
			// sends login submission to the server
			let v = this
			v.axios.post('/api/login',{email:v.email,password:v.password}).then(response=>{
				if( !response )
				{
					v.msg = "Login or Password Invalid, Try again or contact an administrator for help."
					return;
				}
				if(response.data.token)
				{
					v.cookies.set('jwt',response.data.token)
					//v.router.push('/')
					window.location = '/';
				}
			}).catch(error=>{
				console.log(error)
			})
		}
	},
}
</script> 
<style>
	.login-form{
		max-width: 600px; 
		color: #343838; 
		background-color:;
		background-image: linear-gradient(#ECE5CE,#F1D4AF);
/*		background-image: url('@/assets/wp-blue.jpg');*/
	}
</style>