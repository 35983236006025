<template>
	<div v-if="access_denied">
		<h1 class="mt-5 text-center">Access Denied</h1>
	</div>
	<span v-if="!access_denied">
		<div class="titlebar titlebar-second">
			<div class="row">
				<div class="col">
					User Account
				</div>
				<div class="col text-end">
					<router-link class="nav-link inline-ov" to="/users">Close</router-link>
					<a class="nav-link inline-ov ms-5" @click="delete_user" href="#">Delete</a>
					<a class="nav-link inline-ov ms-5" @click="save_changes" href="#">Save Changes</a>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<div v-if="user_loaded">
				<div class="row">
					<div class="col-md-4">
						<div class="mb-3">
							<label for="full_name" class="form-label">Full Name</label>
							<input type="text" class="form-control" id="full_name" placeholder="John Smith" v-model="user.full_name">
						</div>
						<div class="mb-3">
							<label for="email" class="form-label">Email address</label>
							<input type="email" class="form-control" id="email" placeholder="name@example.com" v-model="user.email" autocomplete="off">
						</div>
						<div class="mb-3" v-if="user.user_id > 0">
							<label for="passwd" class="form-label">Set a New Password</label>
							<div class="input-group">
								<input type="password" class="form-control" id="passwd" v-model="passwd" autocomplete="off">
								<button type="button" class="btn btn-outline-secondary" @click="reset_password">Set Password</button>
							</div>
							<p class="text-success" v-if="passwd_msg">{{passwd_msg}}</p>
						</div>
						<div class="mb-3">
							<label for="mattermost_id" class="form-label">Mattermost ID</label>
							<div class="input-group">								
								<span class="input-group-text" id="basic-addon1">@</span>
								<input type="text" class="form-control" id="mattermost_id" placeholder="eg. tomjones" v-model="user.mattermost_id" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="col-md-2">
					</div>
					<div class="col" v-if="user.manage_permissions">
						<h5>Permissions</h5>
						<div class="mb-3">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="manage_users" v-model="user.manage_users" @change="set_perm">
								<label class="form-check-label" for="manage_users">
									Manage User Accounts
								</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="manage_permissions" v-model="user.manage_permissions" @change="set_perm">
								<label class="form-check-label" for="manage_permissions">
									Manage Permissions
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</span>
</template>
<script>
	export default{
		Name: 'UserEdit',
		data(){return{
			user_loaded: false,
			user: {},
			passwd: '',
			passwd_msg: '',
			access_denied: false,
		}},
		methods:{
			load_user(uid){
				let v = this
				v.axios.get('/api/user/'+String(uid)).then(response=>{
					v.user = response.data
					v.user_loaded = true
				}).catch(error=>{
					if( error.response.data == 'Access Denied' )
						v.access_denied = true
				})
			},
			save_changes(){
				let v = this
				v.axios.post('/api/save_user',v.user).then(response=>{
					v.router.push('/users');
				})
			},
			delete_user(){
				let v = this
				if( window.confirm('Are you sure you wish to delete this user?') )
					v.axios.post('/api/del_user',{user_id:v.user.user_id}).then(response=>{
						v.router.push('/users');
					})
			},
			reset_password(){
				let v = this
				v.axios.post('/api/passwd',{passwd: v.passwd,user_id:v.user.user_id}).then(response=>{
					v.passwd = ''
					v.passwd_msg = 'Password Reset Successfully'
				})
			},
			set_perm(){
				let v = this
				v.axios.post('/api/set_permission',{permission:'manage_users',setting:v.user.manage_users,user_id:v.user.user_id}).then(response=>{
				})
			},
		},
		mounted(){
			let id = this.$route.params.id
			if( id )
				this.load_user(id);
			else
			{
				this.user.user_id = null
				this.user_loaded = true
			}
		},
	}
</script> 
