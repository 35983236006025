import Cookies from 'js-cookie'
import router from './router'

const axios = require('axios')

let token = Cookies.get('jwt')

let headers = {}
if( token )
	headers['Authorization'] = 'Bearer '+token
headers['X-Requested-With'] = 'XMLHttpRequest'

let ajax = {}
if( process.env.NODE_ENV === "production" )
	ajax = axios.create({
		baseURL: 'https://api.mtracker.easyonnet.com/',
		timeout: 5000,
		headers: headers,
	})
else
	ajax = axios.create({
		baseURL: 'http://localhost:8080/',
		timeout: 5000,
		headers: headers,
	})

// Add a response interceptor - Redirects user IMMEDIATELY upon detection of auth failure to the login page
ajax.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
  }, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	if(error.response.status==401 || (error.response.status==500 && error.response.data.message=='Expired token')){
		Cookies.remove('jwt')
		router.push('/login')
	}
	else
		return Promise.reject(error);
  });

const kill_token = ()=>{
	Cookies.remove('jwt')
}

export {ajax,kill_token}
