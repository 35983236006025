const moment = require('moment')

function display_date(dt){if(dt) return moment(dt).format("MMM Do YYYY")}

function display_datetime(dt){if(dt) return moment(dt).format("MMM Do, YYYY h:mm a")}

function today(with_time){
	if(!with_time)
		return html_date(new Date())
	else
		return html_datetime(new Date())
}

function html_date(rawdate){
	let date = null
	if( typeof(rawdate) == Object && rawdate.constructor.name == 'moment' )
		date = rawdate
	else
		date = moment(rawdate)
	return date.format(moment.HTML5_FMT.DATE)
}

function html_datetime(rawdate){
	let date = null
	if( typeof(rawdate) == Object && rawdate.constructor.name == 'moment' )
		date = rawdate
	else
		date = moment(rawdate)
	return date.format(moment.HTML5_FMT.DATETIME_LOCAL)
}

export {today, display_date, display_datetime, html_date, html_datetime};
