<template>
	<div class="titlebar titlebar-second">
		<div class="row">
			<div class="col">
				Category
				<span v-if="category.category_id">: {{category.name}}</span>
			</div>
			<div class="col text-end">
				<router-link to="/maintenance" class="nav-link inline-ov ms-5">Close</router-link>
				<router-link to="/subject" class="nav-link inline-ov ms-5">Create New Subject</router-link>
			</div>
		</div>
	</div>
	<div class="container mt-3">
		<div class="mt-1 p-2 border rounded">
			<div class="row">
				<div class="col-md-3">
					<h5 class="ms-2 mt-2">{{category.name}}</h5>
				</div>
				<div class="col-md-9">
					<i class="text-muted">{{category.description}}</i>
				</div>
			</div>
		</div>

		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th>Subject</th>
					<th v-if="has_customer_name">
						Customer
					</th>
					<th>Tag Line</th>
					<th>Last Service</th>
					<th colspan="2">Next Service</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody v-if="subjects_loaded">
				<tr v-for="s in subjects" :key="s.subject_id" @click="open_subject(s.subject_id)>-1">
					<td>
						{{s.name}}
						<div v-if="has_domain"><small>{{s.domain}}</small></div>
					</td>
					<td v-if="has_customer_name">
						{{s.customer_name}}
						<div v-if="has_customer_whmcs && s.customer_whmcs"><small>{{s.customer_whmcs.toUpperCase()}} WHMCS</small></div>
					</td>
					<td>{{s.tagline}}</td>
					<td>{{fmt_last_date(s.last_service)}}</td>
					<td>{{fmt_next_date(s.next_service)}}</td>
					<td>{{fmt_next(s.next_service)}}</td>
					<td>
						<div class="badge bg-danger" v-show="s.status=='overdue'">OverDue!</div>
						<div class="badge bg-success" v-show="s.status=='ok'">Ok</div>
						<div class="badge bg-warning" v-show="s.status=='hold'">On Hold</div>
						<div class="badge bg-light" v-show="s.status=='cancelled'">Cancelled</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	import {display_date,display_datetime} from '@/MegaDate.js'
	const moment = require('moment')
	export default{
		Name: 'MaintenanceCategoryDashboard',
		data(){return{
			category: {
				optional_fields: [],
			},
			category_loaded: false,
			subjects: [],
			subjects_loaded: false,
		}},
		computed: {
			has_customer_name(){
				return this.category.optional_fields.indexOf('customer_name') > -1
			},
			has_customer_whmcs(){
				return this.category.optional_fields.indexOf('customer_whmcs') > -1
			},
			has_domain(){
				return this.category.optional_fields.indexOf('domain') > -1
			},
			has_assigned_user_id(){
				return this.category.optional_fields.indexOf('assigned_user_id') > -1
			},
			has_budget(){
				return this.category.optional_fields.indexOf('budget') > -1
			},
		},
		methods:{
			fmt_last_date(dt){
				return !dt ? '' : display_datetime(dt)
			},
			fmt_next_date(dt){
				if( !dt )
					return ''
				if( moment(dt).year() == moment().year() )
					return moment(dt).format('MMM Do')
				else
					return moment(dt).format('MMM Do YY')
			},
			fmt_next(dt){
				return !dt ? '' : moment(dt).fromNow()
			},
			open_subject(subject_id){
				this.$router.push('/subject/'+String(subject_id))
			},
			load(id){
				let v = this
				v.axios.get('/api/category/'+String(id)).then(response=>{
					this.category = response.data
					v.category.optional_fields = JSON.parse(v.category.optional_fields)
					this.category_loaded = true
					this.load_subjects()
				})
			},
			load_subjects()
			{
				let v = this
				v.axios.get('/api/subjects',{params:{category_id: v.category.category_id}}).then(response=>{
					this.subjects = response.data
					this.subjects_loaded = true
				})
			},
		},
		mounted(){
			let id = this.$route.params.id
			this.load(id)
			this.cookies.set('maintenance_category_id',id)
		}
	}
</script> 
