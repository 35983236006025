<template>
	<div class="login-container">
		<LoginForm />
	</div>
</template>
<script>
	import LoginForm from '@/components/LoginForm.vue'

	export default{
		Name: "LoginView",
		components: {LoginForm},
	}
</script> 
<style>
	.login-container{
		height: 100%;
		width: 100%;
		position: fixed;
		background-image: url('@/assets/wp-wood.jpg');
	}
	.main-bg{
		height: 100%;
		
	}
</style>